<template>
  <b-card :key="$route.params.id">
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Office"
            rules="required"
          >
            <b-form-group
              label="Office"
              label-for="Office"
            >
              <b-form-select
                id="Office"
                v-model="editPaymentStatusForm.office_id"
                :state="getValidationState(validationContext)"
                :options="optionsOffice"
                trim
                placeholder="Office"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              label="status"
              label-for="status"
            >
              <b-form-select
                id="status"
                v-model="editPaymentStatusForm.payment_status"
                :state="getValidationState(validationContext)"
                :options="optionStatus"
                trim
                placeholder="status"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          v-if="editPaymentStatusForm.payment_status === 'credit' || editPaymentStatusForm.payment_status === 'paid'"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="amount"
            rules="required"
          >
            <b-form-group
              label="Amount"
              label-for="Amount"
            >
              <b-form-input
                id="amount"
                v-model="editPaymentStatusForm.payment_amount"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Enter credit amount"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="editPaymentStatusForm.payment_status === 'credit' || editPaymentStatusForm.payment_status === 'paid'"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="currency"
            rules="required"
          >
            <b-form-group
              label="currency"
              label-for="currency"
            >
              <v-select
                id="currency"
                v-model="editPaymentStatusForm.payment_currency"
                :options="optionCurrency"
                label="text"
                :state="getValidationState(validationContext)"
                :searchable="true"
                placeholder="Enter your currency"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          v-if="editPaymentStatusForm.payment_status === 'paid'"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="payment"
            rules="required"
          >
            <b-form-group
              label="payment"
              label-for="payment"
            >
              <b-form-select
                id="payment-method"
                v-model="editPaymentStatusForm.payment_method"
                :options="optionsPaymentMethod"
                :state="getValidationState(validationContext)"
                placeholder="Enter credit payment"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="editPaymentStatusForm.payment_status === 'paid' &&
            ['bank transfer', 'cash', 'check'].includes(editPaymentStatusForm.payment_method)"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="payment-date"
            rules="required"
          >
            <b-form-group
              label="Payment Date"
              label-for="Payment Date"
            >
              <b-form-input
                id="payment-date"
                v-model="editPaymentStatusForm.payment_date"
                type="date"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <!--
        "bank_name"
    "bank_account_number"
    "transaction_reference_number" -->
        <b-col
          v-if="editPaymentStatusForm.payment_status === 'paid' &&
            ['bank transfer', 'check'].includes(editPaymentStatusForm.payment_method)"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="bank-name"
            rules="required"
          >
            <b-form-group
              label="Bank Name"
              label-for="Bank Name"
            >
              <b-form-input
                id="bank_name"
                v-model="editPaymentStatusForm.bank_name"
                type="text"
                placeholder="Enter Your Bank"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          v-if="editPaymentStatusForm.payment_status === 'paid' &&
            ['bank transfer', 'check'].includes(editPaymentStatusForm.payment_method)"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="bank-account-number"
            rules="required"
          >
            <b-form-group
              label="Bank Account Number"
              label-for="Bank Account Number"
            >
              <b-form-input
                id="bank_account_number"
                v-model="editPaymentStatusForm.bank_account_number"
                type="number"
                placeholder="Enter Your Bank Account Number"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          v-if="editPaymentStatusForm.payment_status === 'paid' &&
            ['bank transfer', 'check'].includes(editPaymentStatusForm.payment_method)"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            name="transaction-reference-number"
            rules="required"
          >
            <b-form-group
              :label="`${editPaymentStatusForm.payment_method === 'check' ? 'Check Number' : 'Transaction Reference Number'}`"
              :label-for="`${editPaymentStatusForm.payment_method === 'check' ? 'Check Number' : 'Transaction Reference Number'}`"
            >
              <b-form-input
                id="transaction_reference_number"
                v-model="editPaymentStatusForm.transaction_reference_number"
                type="number"
                :placeholder="`${editPaymentStatusForm.payment_method === 'check' ? 'Enter Your Check Number' : 'Enter Your Transaction Reference Number'}`"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="editPaymentStatus()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="editPaymentStatus()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import VSelect from 'vue-select'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
    'v-select': VSelect, // Register vue-select component
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      optionsOffice: [],
      optionCurrency: [],
      optionsPaymentMethod: [
        { value: 'cash', text: 'Cash' },
        { value: 'check', text: 'Check' },
        { value: 'wallet', text: 'Wallet' },
        { value: 'bank transfer', text: 'Bank Transfer' },
      ],
      optionStatus: [
        { value: 'paid', text: 'Paid' },
        { value: 'credit', text: 'credit' },
        { value: 'canceled', text: 'Canceled' },
      ],
      loader: false,
      validationErrors: {},
      errors: {},
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const editPaymentStatusForm = ref({
    })
    return {
      getValidationState,
      editPaymentStatusForm,
    }
  },
  watch: {
    // Watch for changes in the route parameter 'id'
    '$route.params.id': {
      immediate: true, // Runs on component load
      handler(newId) {
        if (newId) {
          this.getPaymentStatus()
        }
      },
    },
  },
  mounted() {
    this.getOffice()
    this.getCurrency()
    this.getPaymentStatus()
  },

  methods: {
    getPaymentStatus() {
      if (this.$route.params.id) {
        axios
          .get(`requests/${this.$route.params.id}/short-info`)
          .then(res => {
            const offerData = decryptData(res.data.payload).data?.request
            this.editPaymentStatusForm = {
              office_id: offerData.office?.id,
              payment_status: offerData.payment_status,
              payment_amount: offerData.payment_amount,
              payment_currency: offerData.payment_currency,
              payment_method: offerData.payment_method,
              payment_date: offerData.payment_date,
              bank_name: offerData.bank_name,
              bank_account_number: offerData.bank_account_number,
              transaction_reference_number: offerData.transaction_reference_number,
            }
          })
      }
    },

    getOffice() {
      this.loader = true
      axios
        .get('office')
        .then(res => {
          const data = decryptData(res.data.payload).data.offices
          this.optionsOffice = data.map(item => ({
            value: item.id,
            text: item.name,
          }))
        })
        .finally(() => {
          this.loader = false
        })
    },
    getCurrency() {
      this.loader = true
      axios.get('https://gulftic-system.fci.group/api/currency/available')
        .then(res => {
          this.optionCurrency = decryptData(res.data.payload).data?.currencies
        })
        .finally(() => {
          this.loader = false
        })
    },

    editPaymentStatus() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        let fieldsToInclude
        if (this.editPaymentStatusForm.payment_status === 'canceled') {
          fieldsToInclude = ['office_id', 'payment_status']
        } else if (this.editPaymentStatusForm.payment_status === 'credit') {
          fieldsToInclude = ['office_id', 'payment_status', 'payment_amount', 'payment_currency']
        } else {
          fieldsToInclude = Object.keys(this.editPaymentStatusForm)
        }

        // Iterate over selected fields
        fieldsToInclude.forEach(key => {
          let value = this.editPaymentStatusForm[key]
          if (value === null) {
            value = '' // Replace null with an empty string
          }
          formData.append(key, typeof value === 'object' ? JSON.stringify(value) : value)
        })

        axios
          .post(`requests/${this.$route.params.id}/payment_status`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'requests' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
